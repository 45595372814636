<template>
    <div class="icon">
        <slot/>
        <span>{{title}}</span>
    </div>
</template>

<script>
export default {
    name: 'MenuItemFooter',
    props: ['title'],
}
</script>

<style scoped>
.icon{
    display: flex;
    flex-direction: column;
    font-size: 10px;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    height: 40px;
}
</style>