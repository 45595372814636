<template>
    <footer class="footer">
        <add-order-btn v-if="isShowOrderBtn" />
        <menu-item title="Меню"  @click="$router.push('/menu')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.9868 18H3.01317C1.66228 18 1.66228 20 3.01317 20H17.9868C19.3377 20 19.3377 18 17.9868 18Z" fill="#555F67"/>
                <path d="M14.2252 11.0005H2.77478C1.74174 10.9645 1.74174 13.0355 2.77478 12.9995H14.2252C15.2583 13.0355 15.2583 10.9645 14.2252 11.0005Z" fill="#555F67"/>
                <path d="M3.19196 5.99954H20.808C22.3973 6.03549 22.3973 3.96451 20.808 4.00046H3.19196C1.60268 3.96444 1.60268 6.03549 3.19196 5.99954Z" fill="#555F67"/>
            </svg>
        </menu-item>
        <menu-item title="Заказы" @click="$router.push('/')" v-if="isShowDeal">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 4.5L22 4.5V3H2V4.5L4.01562 4.5V20.25C4.01562 20.515 4.15549 20.7604 4.38355 20.8954C4.61161 21.0304 4.89398 21.035 5.12634 20.9076L7.45343 19.631L9.2302 20.8659C9.47875 21.0386 9.80679 21.045 10.0619 20.8821L12.0071 19.6399L13.9531 20.8822C14.2081 21.045 14.536 21.0386 14.7846 20.8659L16.5621 19.6309L18.8893 20.9076C19.1216 21.035 19.404 21.0304 19.6321 20.8954C19.8601 20.7604 20 20.515 20 20.25V4.5ZM18.5 4.5L5.51562 4.5V18.9831L7.13928 18.0924C7.38881 17.9556 7.69432 17.9717 7.92803 18.1341L9.67582 19.3489L11.6034 18.1179C11.8495 17.9607 12.1645 17.9607 12.4106 18.1178L14.339 19.349L16.0877 18.1341C16.3214 17.9717 16.6269 17.9556 16.8763 18.0924L18.5 18.9831V4.5ZM8 13.5H12V12H8V13.5ZM16 9.5H8V8H16V9.5Z" fill="#525C68"/>
            </svg>
        </menu-item>
        <menu-item title="Оплаты" @click="$router.push('/pays')" v-if="isShowInvoice">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.25 7.01167H19C20.1046 7.01167 21 7.9071 21 9.01167V11.0234V12.5234V15.542V17.042V18.0551C21 19.7119 19.6569 21.0551 18 21.0551H6C4.34315 21.0551 3 19.7119 3 18.0551V9.01168C3 7.90711 3.89543 7.01167 5 7.01167H7.87845L14.3591 3.25847C15.076 2.84329 15.9921 3.08877 16.4054 3.80677L18.25 7.01167ZM16.5193 7.01167L15.1074 4.55851L10.8715 7.01167H16.5193ZM19 8.51167H5C4.72386 8.51167 4.5 8.73553 4.5 9.01168V18.0551C4.5 18.8835 5.17157 19.5551 6 19.5551H18C18.8284 19.5551 19.5 18.8835 19.5 18.0551V17.042H14C12.8954 17.042 12 16.1466 12 15.042V13.0234C12 11.9188 12.8954 11.0234 14 11.0234H19.5V9.01167C19.5 8.73553 19.2761 8.51167 19 8.51167ZM19.5 12.5234V15.542H14C13.7239 15.542 13.5 15.3181 13.5 15.042V13.0234C13.5 12.7473 13.7239 12.5234 14 12.5234H19.5Z" fill="#525C68"/>
            </svg>
        </menu-item>
        <menu-item title="Информация" @click="showInfo" v-if="needInfo">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.98626 18.0132C9.30251 21.3286 14.6986 21.3292 18.0136 18.0134C21.3288 14.6972 21.3287 9.30102 18.0137 5.98596L18.0136 5.98588C14.6985 2.66987 9.3015 2.66987 5.9864 5.98588C2.67107 9.30214 2.67038 14.6983 5.98626 18.0132ZM19.0744 19.0739C15.1734 22.976 8.82749 22.9747 4.92574 19.074C1.02362 15.173 1.02494 8.82711 4.9256 4.92537C8.82651 1.02338 15.1735 1.02338 19.0744 4.92537C22.9753 8.82631 22.9751 15.1721 19.0744 19.0739ZM12 16.9785C11.5858 16.9785 11.25 16.6427 11.25 16.2285L11.25 11.1355C11.25 10.7213 11.5858 10.3855 12 10.3855C12.4142 10.3855 12.75 10.7213 12.75 11.1355L12.75 16.2285C12.75 16.6427 12.4142 16.9785 12 16.9785ZM11 7.98437C11 7.43209 11.4477 6.98438 12 6.98438C12.5523 6.98438 13 7.43209 13 7.98438C13 8.53666 12.5523 8.98438 12 8.98438C11.4477 8.98438 11 8.53666 11 7.98437Z" fill="#525C68"/>
            </svg>
        </menu-item>
        <menu-item title="Чат" @click="openChat" v-if="isChat">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.904 6.02015C17.4486 3.72788 15.4261 2 13 2H11C8.57393 2 6.55142 3.72788 6.09597 6.02015C6.06387 6.02337 6.03188 6.02697 6 6.03095C4.02684 6.277 2.5 7.96019 2.5 10V12C2.5 14.0398 4.02684 15.723 6 15.9691C6.1638 15.9895 6.33067 16 6.5 16H7.5V14.5V7.5L7.5 7C7.5 5.067 9.06701 3.5 11 3.5H13C14.933 3.5 16.5 5.067 16.5 7V7.5V14.5V15.2514C16.4829 16.7154 15.5669 17.9633 14.2782 18.469C13.8855 17.6027 13.0131 17 12 17C10.6193 17 9.5 18.1193 9.5 19.5C9.5 20.8807 10.6193 22 12 22C13.2109 22 14.2207 21.1391 14.4508 19.996C16.2677 19.4461 17.6476 17.8899 17.9419 15.9759C17.9613 15.9737 17.9807 15.9715 18 15.9691C19.9732 15.723 21.5 14.0398 21.5 12V10C21.5 7.96019 19.9732 6.277 18 6.03095C17.9681 6.02697 17.9361 6.02337 17.904 6.02015ZM6 14.45C4.85888 14.2184 4 13.2095 4 12V10C4 8.79052 4.85888 7.78164 6 7.55001V14.45ZM20 10C20 8.79052 19.1411 7.78164 18 7.55001V14.45C19.1411 14.2184 20 13.2095 20 12V10ZM12 20.5C12.5523 20.5 13 20.0523 13 19.5C13 18.9477 12.5523 18.5 12 18.5C11.4477 18.5 11 18.9477 11 19.5C11 20.0523 11.4477 20.5 12 20.5Z" fill="#0B1F33"/>
            </svg>
        </menu-item>
    </footer>
    <popup :show="showPopup" @close="showPopup = false" ref="about-popup">
        <block class="popup-block">
            <about />
        </block>
    </popup>
    <B24chat @no-chat="isChat = false" v-if="isChat"/>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import About from '../content/About.vue';
import B24chat from '../content/B24chat.vue';
import Popup from '../ui/Popup.vue';
import Block from './Block.vue';
import MenuItemVue from './footer/MenuItem.vue';
import AddOrderBtn from './order/AddOrderBtn.vue';

export default {
    name: 'Footer',
    components:{
        AddOrderBtn,
        MenuItem: MenuItemVue,
        Popup,
        Block,
        About,
        B24chat
    },
    data(){
        return {
            showPopup: false,
            isChat: true,
            isShowOrderBtn: false,
            isShowInvoice: false,
            isShowDeal: false,
        }
    },
    computed:{
        needInfo(){
            let fItemsCount = 1;
            if(this.isShowInvoice) fItemsCount++;
            if(this.isShowDeal) fItemsCount++;
            if(this.isChat) fItemsCount++;

            return fItemsCount % 2 > 0;
        }
    },
    methods:{
        ...mapMutations(['toggleAboutPopup']),
        ...mapActions('options', ['getBaseOption']),
        showInfo(){
            this.toggleAboutPopup();
        },
        openChat(){
            document.querySelector('.b24-widget-button-popup').click();
        },
        async init(){
            this.isShowOrderBtn = await this.getBaseOption('isShowOrderBtn');
            this.isShowInvoice = await this.getBaseOption('isShowInvoice');
            this.isShowDeal = await this.getBaseOption('isShowDeal');
        }
    },
    mounted(){
        this.init()
    }
}
</script>

<style scoped>
.footer{
    padding: 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    margin: 0 -20px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    justify-content: space-around;
}
</style>

<style>
.block.popup-block main{
    padding: 0;
}
</style>