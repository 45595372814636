<template>
    <div class="personal-widget">
        <div class="avatar">
            <img src="https://podpislon.ru/upload/content/blog/author1.jpg" alt="">
        </div>
        <div class="name">
            Иван Иванов
        </div>
    </div>
</template>

<style scoped>
    .personal-widget{
        border-radius: 15px;
        border: 2px solid #BDBDBD;
        padding: 10px 25px;
        display: flex;
        justify-content: space-between;
        color: #6F6F6F;
        align-items: center;
    }
    .avatar{
        width: 43px;
        height: 43px;
        border-radius: 50%;
        background-color: #fff;
        overflow: hidden;
    }
    .avatar img{
        width: 100%;
    }
    .arrow{
        cursor: pointer;
    }
    @media screen and (max-width: 767px) {
        .personal-widget{
            grid-row-start: 2;
            grid-column-start: 1;
            grid-row-end: 2;
            grid-column-end: 4;
        }
    }
</style>