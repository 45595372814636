<template>
    <div class="input-block">
        <label v-if="label">{{label}}</label>
        <div class="error" v-if="error.length">{{error}}</div>
        <input v-model="val" @input="$emit('update:input', val)" :type="type || 'text'"/>
    </div>
</template>

<script>
import { computed, ref } from 'vue'

export default {
    // eslint-disable-next-line
    name: 'Input',
    props: ['label', 'input', 'error', 'type'],
    emits: ['update:input'],
    setup(props) {
        let val = ref(props.input);
        const error = computed(()=> props.error);
        return{
            val,
            error
        }
    },
}
</script>

<style scoped>
label{
    color: #A7ADB3;
    font-size: 12px;
}
input{
    border-radius: 2px;
    border: 1px solid #C7CDD2;
    background: #F0F3F4;
    color: #000;
    font-size: 14px;
    padding: 12px;
    width: 260px;
    margin-bottom: 15px;
    margin-top: 4px;
    display: block;
    color: #949494;
    outline: none;
}
input[readonly]{
    cursor: pointer;
}
.input-block{
    width: fit-content;
}
.error{
    color: #E11432;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
</style>