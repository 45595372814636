export default {
    base_url: '/',
    getOrders(){
        return fetch(`${this.base_url}api/orders/`)
            .then(res => res.json());
    },
    getCategories(){
        return fetch(`${this.base_url}api/orders/categories/`)
            .then(res => res.json());
    },
    getStages(catId){
        return fetch(`${this.base_url}api/orders/stages/${catId}/`)
            .then(res => res.json());
    },
    getProducts(orderId){
        return fetch(`${this.base_url}api/order/products/${orderId}/`)
            .then(res => res.json());
    },
    getOrder(id){
        return fetch(`${this.base_url}api/orders/?id=${id}`)
        .then(res => res.json());
    },
    create(data){
        const fd = new FormData;
        for(let key in data){
            fd.append(key, data[key]);
        }
        return fetch(`${this.base_url}api/orders/create/`, {
            method: 'POST',
            body: fd
        })
            .then(res => res.json());
    }
}