<template>
    <div class="btn"><slot/>{{title}}</div>
</template>
<script>
// удалил из кода @click="$emit('click')" - кажется не нужно, если что-то сломалось
// возможно проблема тут
export default {
    props: ['title'],
}
</script>
<style scoped>
.btn{
    border-radius: 5px;
    background: #F79900;
    padding: 10px 18px;
    color: #FFF;
    font-size: 12px;
    font-weight: 700;
    display: inline-block;
}
</style>