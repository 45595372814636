import PaymentsAPI from '@/api/payments';

export default {
    namespaced: true,
    state(){
        return {
            payments: [],
            stages:[]
        }
    },
    getters:{
        getPayments: (state) => (orderId) => state.payments[`ORDER_${orderId}`],
        getAllPayments: (state) => () => state.payments[`ALL`],
        getPayment: (state) => (paymentId, orderId) => {
            if(orderId > 0){
                if(!state.payments[`ORDER_${orderId}`]) return null;
                return state.payments[`ORDER_${orderId}`].find(p => +p.id === +paymentId)
            } else {
                if(!state.payments[`ALL`]) return null;
                return state.payments[`ALL`].find(p => +p.id === +paymentId)
            }
        },
    },
    actions:{
        async loadPayments({state, commit}, orderId){
            if(!state.payments[`ORDER_${orderId}`]){
                const payments = await PaymentsAPI.getPayments(orderId);
                commit('setPayments', {payments: payments.result, orderId});
            }
            return state.payments;
        },
        async loadAllPayments({state, commit}){
            if(!state.payments[`ALL`]){
                const payments = await PaymentsAPI.getAllPayments();
                commit('setAllPayments', {payments: payments.result});
            }
            return state.payments;
        },
        async getStages({state, commit}){
            if(!state.stages.length){
                const stages = await PaymentsAPI.getStages();
                commit('setStages', stages.result);
            }
            return state.stages;
        },
    },
    mutations:{
        setPayments(state, {payments, orderId}){
            state.payments[`ORDER_${orderId}`] = payments;
        },
        setAllPayments(state, {payments}){
            state.payments[`ALL`] = payments;
        },
        setStages(state, val){
            state.stages = val;
        },
    }
  }