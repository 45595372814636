export default {
    base_url: '/',
    getUser(){
        return fetch(`${this.base_url}api/users/current/`)
            .then(res => res.json());
    },
    getUserById(id){
        return fetch(`${this.base_url}api/users/${id}/`)
            .then(res => res.json());
    },
    isAuth(){
        return fetch(`${this.base_url}api/users/check-auth/`)
            .then(res => res.json());
    },
    auth(login, pass){
        const fd = new FormData;
        fd.append('login', login);
        fd.append('password', pass);
        return fetch(`${this.base_url}api/users/auth/`, {
                method: 'POST',
                body: fd
            })
            .then(res => res.json());
    },
    logout(){
        return fetch(`${this.base_url}api/users/logout/`, {
            method: 'POST',
        })
        .then(res => res.json());
    },
    changePass(pass, confirm){
        const fd = new FormData;
        fd.append('pass', pass);
        fd.append('confirm', confirm);
        return fetch(`${this.base_url}api/users/change-pass/`, {
                method: 'POST',
                body: fd
            })
            .then(res => res.json());
    },
}