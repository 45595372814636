<template>
    <div class="popup-layout" @click="close" v-if="getIsAboutPopup">
        <div class="popup-content"><slot /></div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'PopUp',
    computed: {
        ...mapGetters(['getIsAboutPopup']),
        visible(){
            return this.getIsAboutPopup;
        }
    },
    methods: {
        ...mapMutations(['toggleAboutPopup']),
        close(e){
            if(e.target.classList.contains('popup-layout'))
                this.toggleAboutPopup();
        }
    }
}
</script>

<style>
.popup-layout{
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(27, 27, 27, 0.2);
    position: absolute;
}
.popup-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>