import OptionsAPI from '@/api/options';

export default {
    namespaced: true,
    state(){
        return {
            baseOptions: null,
        }
    },
    getters:{
    },
    actions:{
        async getBaseOptions({state, commit}){
            if(!state.baseOptions){
                const bOpts = await OptionsAPI.getBaseOptions();
                commit('setBaseOptions', bOpts);
            }
            return state.baseOptions;
        },
        async getBaseOption({state, dispatch}, name){
            if(!state.baseOptions){
                await dispatch('getBaseOptions');
            }
            return state.baseOptions[name] ?? null;
        }
    },
    mutations:{
        setBaseOptions(state, bOpts){
            if(bOpts === null) bOpts = {};
            state.baseOptions = bOpts;
        },
    }
  }