import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Page from '@/components/PageSkeleton.vue'

const app = createApp(App);
app.component('Page', Page);
app.use(store).use(router);
app.mount('#app');
