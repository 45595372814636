import helper from '@/libs/helper'
export default{
    async init(){
        const initCred = await fetch('/api/users/wa-init/', {
            method: 'POST'
        })
        .then(res => res.json());
        try{
            const publicKeyCredentialCreationOptions = initCred.result;
            helper.bta(publicKeyCredentialCreationOptions);
            this.create(await navigator.credentials.create(publicKeyCredentialCreationOptions));
        } catch (e){
            console.error(e);
        }
        return;
    },
    create(cred){
        const body = new FormData();
        body.append('transport', cred.response.getTransports?cred.response.getTransports():null);
        body.append('client', cred.response.clientDataJSON?helper.atb(cred.response.clientDataJSON):null);
        body.append('attest', cred.response.attestationObject?helper.atb(cred.response.attestationObject):null);
        fetch('/api/users/wa-register/', {
            method: 'POST',
            body
        })
        .then(res => res.json())
        .then(res => {console.log(res)});
    },
    async validate(){
        const body = new FormData;
        body.append('action', 'args');
        const initCred = await fetch('/api/users/wa-login/', {
            method: 'POST',
            body
        })
        .then(res => res.json());
        try{
            const publicKeyCredentialCreationOptions = initCred.result;
            helper.bta(publicKeyCredentialCreationOptions);
            return this.login(await navigator.credentials.get(publicKeyCredentialCreationOptions));
        } catch (e){
            console.error(e);
        }
        return;
    },
    login(cred){
        const body = new FormData();
        body.append('id', cred.rawId?helper.atb(cred.rawId):null);
        body.append('client', cred.response.clientDataJSON?helper.atb(cred.response.clientDataJSON):null);
        body.append('auth', cred.response.authenticatorData?helper.atb(cred.response.authenticatorData):null);
        body.append('sig', cred.response.signature?helper.atb(cred.response.signature):null);
        body.append('user', cred.response.userHandle?helper.atb(cred.response.userHandle):null);
        return fetch('/api/users/wa-login/', {
            method: 'POST',
            body
        })
        .then(res => res.json())
        .then(res => res.result);
    }
}