import OrderAPI from '@/api/orders';

export default {
    namespaced: true,
    state(){
        return {
            categories: [],
            stages: {},
            currency: 'RUB',
            orders: []
        }
    },
    getters:{
        getCategories(state){
            return state.categories;
        },
        getCurrency(state){
            return state.currency;
        }
    },
    actions:{
        async loadGetCategories({state, commit}){
            if(!state.categories.length){
                const cats = await OrderAPI.getCategories();
                commit('setCats', cats.result);
            }
            return state.categories;
        },
        async getStages({state, commit}, catId){
            if(!state.stages[`C_${catId}`]){
                const stages = await OrderAPI.getStages(catId);
                commit('setStages', {catId, stages: stages.result});
            }
            return state.stages[`C_${catId}`];
        },
        async getOrders({state, commit}){
            if(!state.orders.length){
                const orders = await OrderAPI.getOrders();
                commit('setOrders', orders.result);
            }
            return state.orders;
        },
        async getOrder({state, commit, dispatch}, id){
            if(!state.orders.length){
                await dispatch('getOrders');
            }
            const orderIdx = state.orders.findIndex(order => +order.ID === +id);
            if(orderIdx < 0){
                const order = await OrderAPI.getOrder(id);
                commit('setOrder', order.result);
                return order.result;
            }
            return state.orders[orderIdx];
        }
    },
    mutations:{
        setOrders(state, val){
            state.orders = val;
        },
        setOrder(state, val){
            state.orders.push(val);
        },
        setCats(state, val){
            state.categories = val;
        },
        setStages(state, {catId, stages}){
            state.stages[`C_${catId}`] = stages;
        },
        setCurrency(state, val){
            state.currency = val;
        }
    }
  }