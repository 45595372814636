<template>
    <div class="wrapper">
        <Suspense>
            <component :is="comp" v-bind="{...args}"/>

            <!-- loading state via #fallback slot -->
            <template #fallback>
                {{loadText}}
            </template>
        </Suspense>
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapMutations } from 'vuex';

export default {
    props:['title', 'compname', 'extra'],
    methods:{
        ...mapMutations(['setTitle'])
    },
    setup(props){
        const comp = defineAsyncComponent(() => import(`@/components/pages/${props.compname ?? 'Orders'}.vue`));
        
        return {
            comp,
            loadText: 'Загрузка...',
            args: props.extra    
        };
    },
    mounted(){
        this.setTitle(this.title);
    }
}
</script>

<style scoped>
    .wrapper{
        padding-top: 15px;
        padding-bottom: 80px;
    }

    @media screen and (min-width: 768px) {
        .wrapper{
            padding-left: 30px;
        }
    }
</style>