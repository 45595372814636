<template>
    <header class="header">
        <img :src="logo" alt="logo" v-if="logo && logo.length" class="logo"/>
        <div class="signout" @click="logout">Выйти</div>
    </header>
    <div class="subheader">
        <div class="backBtn" @click="$router.back()" v-if="hasHistory">
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                <line x1="0.707107" y1="5.29289" x2="6.70711" y2="11.2929" stroke="#555F67" stroke-width="2"/>
                <line x1="6.70711" y1="0.707107" x2="0.707106" y2="6.70711" stroke="#555F67" stroke-width="2"/>
            </svg>
        </div>
        <div class="title">{{title}}</div>
    </div>
</template>

<script>
import PWidget from '@/components/personal/Widget.vue'
import { mapState, useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router'
import { onMounted, ref, watch } from 'vue'
import users from '@/api/users';

export default {
    name: 'Header',
    components:{
        PWidget
    },
    computed:{
        ...mapState(['title']),
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const hasHistory = ref(false);
        let logo = ref('');
        
        onMounted(async ()=>{
            logo.value = await store.dispatch('general/getLogo')
        });
        watch(
            () => route.fullPath,
            () => {
                hasHistory.value = !!router.options.history.state.back && route.path !== '/menu';
            }
        )

        return {
            hasHistory,
            logo
        }
    },
    methods:{
        async logout(){
            const logout = await users.logout();
            if(logout.result){
                this.$router.go(0);
            }
        }
    }
}
</script>

<style scoped>
.header{
    padding: 20px;
    background-color: #fff;
    display: flex;
    column-gap: 40px;
    align-items: center;
    margin: 0 -20px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    margin-bottom: 15px;
}
.title{
    color: #555B68;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
}
.signout{
    color: #555F67;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}
.subheader{
    display: flex;
}
.subheader .backBtn{
    margin-right: 13px;
}
</style>

<style>
    .header{
        grid-area: A;
    }
    .logo{
        max-height: 40px;
    }
</style>