export default {
    base_url: '/',
    getPayments(orderId){
        return fetch(`${this.base_url}api/order/payments/${orderId}/`)
            .then(res => res.json());
    },
    getAllPayments(){
        return fetch(`${this.base_url}api/order/payments/all/`)
            .then(res => res.json());
    },
    getStages(catId){
        return fetch(`${this.base_url}api/order/payments/stages/`)
            .then(res => res.json());
    },
    getProducts(invoiceId){
        return fetch(`${this.base_url}api/order/payment/products/${invoiceId}/`)
            .then(res => res.json());
    },
}