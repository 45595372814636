import options from "@/api/options";

export default {
    namespaced: true,
    state(){
        return {
            logo: '',
            fields: [],
            fieldsPay: [],
            enumVals: {},
            files: {},
            title: '',
            theme: ''
        }
    },
    getters:{
        
    },
    actions:{
        async getLogo({state, commit}){
            if(!state.logo.length){
                const logo = await fetch('/api/logo/')
                .then(res => res.text());
                commit('setLogo', logo);
            }
            return state.logo;
        },
        async getTitle({state, commit}){
            if(!state.title.length){
                const title = await fetch('/api/title/')
                .then(res => res.json());
                if(title.status) commit('setTitle', title.result);
            }
            return state.title;
        },
        async getTheme({state, commit}){
            if(!state.theme.length){
                const theme = await fetch('/api/theme/')
                .then(res => res.json());
                if(theme.status) commit('setTheme', theme.result);
            }
            return state.theme;
        },
        async getFields({state, commit}){
            if(!state.fields.length){
                //
                const fields = await options.getOption('deal_fields');
                const fieldsDocs = await options.getOption('docs_deal_fields');
                let fields_ = [];
                if(fields){
                    fields_ = JSON.parse(fields);
                }

                if(fieldsDocs){
                    fields_ = [...fields_, ...JSON.parse(fieldsDocs)];
                }

                commit('setFields', fields_);
            }
            return state.fields;
        },
        async getEnumVals({state, commit}, fields){
            //доделать отдачу из хранилища, без запроса
            const enumVals = await options.getEnumVals(fields);
            if(enumVals.status){
                commit('setEnums', enumVals.result);
            }
            return state.enumVals;
        },
        async getEnumValsPay({state, commit}, fields){
            //доделать отдачу из хранилища, без запроса
            const enumVals = await options.getEnumValsPay(fields);
            if(enumVals.status){
                commit('setEnums', enumVals.result);
            }
            return state.enumVals;
        },
        async getFieldsPay({state, commit}){
            if(!state.fieldsPay.length){
                const fields = await options.getOption('invoice_fields');
                
                let fields_ = [];
                if(fields){
                    fields_ = JSON.parse(fields);
                }

                commit('setFieldsPay', fields_);
            }
            return state.fieldsPay;
        },
    },
    mutations:{
        setLogo(state, val){
            state.logo = val;
        },
        setFields(state, val){
            state.fields = val;
        },
        setFieldsPay(state, val){
            state.fieldsPay = val;
        },
        setEnums(state, val){
            for(const v in val){
                state.enumVals[v] = val[v];
            }
        },
        setFiles(state, {id, val}){
            state.files[id] = val;
        },
        setTitle(state, val){
            state.title = val;
        },
        setTheme(state, val){
            state.theme = val;
        },
    }
  }