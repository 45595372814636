import UserAPI from '@/api/users';

export default {
    namespaced: true,
    state(){
        return {
            users: [],
            cuser: []
        }
    },
    getters:{
    },
    actions:{
        async getUser({state, commit}, uid){
            if(!state.users[`U${uid}`]){
                const user = await UserAPI.getUserById(uid);
                commit('setUser', {uid, user: user.result});
            }
            return state.users[`U${uid}`];
        },
        async getCurUser({state, commit}){
            if(!state.cuser.length){
                const user = await UserAPI.getUser();
                commit('setCUser', {user: user.result});
            }
            return state.cuser;
        }
    },
    mutations:{
        setUser(state, {uid, user}){
            state.users[`U${uid}`] = user;
        },
        setCUser(state, {user}){
            state.cuser = user;
        },
    }
  }