<template>
    <div class="add_order_btn" @click="createOrder">
        +
    </div>
</template>
<script>

export default {
    name: 'AddOrderBtn',
    methods:{
        createOrder(){
            this.$router.push('/new-order');
        }
    }
}
</script>

<style scoped>
.add_order_btn{
    position: absolute;
    border-radius: 50%;
    background-color: #f79800;
    width: 50px;
    height: 50px;
    top: -25px;
    text-align: center;
    color: white;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>