<template>
    <div class="block">
        <header v-if="title">{{title}}</header>
        <main>
            <slot></slot>
        </main>
    </div>
</template>

<script>
export default {
    name: 'Block',
    props: ['title']
}
</script>

<style scoped>
.block {
    padding: 20px 20px;
}
.block header{
    color: #555F67;
    font-size: 14px;
    line-height: normal;
    padding-bottom: 20px;
    border-bottom: 2px solid #EFF2F4;
}
.block main{
    padding: 20px 0 0;
}
</style>