import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pays',
    name: 'pays',
    component: () => import(/* webpackChunkName: "pays" */ '../views/PaysView.vue')
  },
  {
    path: '/docs',
    name: 'docs',
    component: () => import(/* webpackChunkName: "docs" */ '../views/DocsView.vue')
  },
  {
    path: '/dogovori',
    name: 'dogovori',
    component: () => import(/* webpackChunkName: "dogovori" */ '../views/DogovoriView.vue')
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import(/* webpackChunkName: "catalog" */ '../views/MenuView.vue')
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import(/* webpackChunkName: "catalog" */ '../views/CatalogView.vue')
  },
  {
    path: '/order/:id',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ '../views/OrderView.vue')
  },
  {
    path: '/payment/:order/:id',
    name: 'payment',
    component: () => import('../views/PaymentView.vue')
  },
  {
    path: '/new-order',
    name: 'new-order',
    component: () => import('../views/NewOrderView.vue')
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import('../views/LoadingView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
