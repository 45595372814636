import { createStore } from 'vuex'
import order from './modules/order'
import user from './modules/user';
import general from './modules/general'
import payments from './modules/payments';
import options from './modules/options';

export default createStore({
  state: {
    title: '',
    base_url: window.location.href,
    isAboutPopup: false
  },
  getters: {
    getBaseUrl(state){
      return state.base_url;
    },
    getIsAboutPopup(state){
      return state.isAboutPopup;
    }
  },
  mutations: {
    setTitle(state, val){
      state.title = val;
    },
    toggleAboutPopup(state){
      state.isAboutPopup = !state.isAboutPopup;
    }
  },
  actions: {
  },
  modules: {
    order,
    user,
    general,
    payments,
    options
  }
})
