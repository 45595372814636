<template>
    <div class="about-container">
        <p class="title">Информация</p>
        <p>Разработчик: Off Group <br/><a target="_blank" href="https://off-group.com/">https://off-group.com/</a></p>

        <p>По всем вопросам, связанным с работой сервиса, а также ваши идеи  по его развитию, пишите на почту поддержки:
            <a href="mailto: support@off-group.com">support@off-group.com</a></p>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>

<style>
.about-container{
    width: 290px;
}
.about-container .title{
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #81868D;
}
.about-container p{
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #555F67;
}
</style>