<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 21.0172V22.9999H21V21.0172C21 19.2313 19.8161 17.6617 18.0989 17.1711L14 16V14.5C14 14.5 15 13 15 12C16.5 12 16.5 9.80011 16.5 9C16.5 8.19989 16 8 16 8V5.5C16 3.5 14.8 3 14 3C13 3 13.5 2.5 11.5 2.5C9 2.5 8 3.5 7.5 4.5C7 5.5 7 7.99986 7 7.99986C7 7.99986 6.5 8 6.5 9C6.5 12 8 11.4999 8 11.9999C8 13 8.66667 13.8332 9 14.4999V16L4.90112 17.1711C3.18391 17.6617 2 19.2313 2 21.0172Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.75 1C21.75 0.585786 21.4142 0.25 21 0.25H18C17.5858 0.25 17.25 0.585786 17.25 1C17.25 1.41421 17.5858 1.75 18 1.75H20.25V5C20.25 5.41421 20.5858 5.75 21 5.75C21.4142 5.75 21.75 5.41421 21.75 5V1ZM2 14.75C1.58579 14.75 1.25 14.4142 1.25 14V10C1.25 9.58579 1.58579 9.25 2 9.25C2.41421 9.25 2.75 9.58579 2.75 10V13.25H5C5.41421 13.25 5.75 13.5858 5.75 14C5.75 14.4142 5.41421 14.75 5 14.75H2ZM21 14.75C21.4142 14.75 21.75 14.4142 21.75 14V10C21.75 9.58579 21.4142 9.25 21 9.25C20.5858 9.25 20.25 9.58579 20.25 10V13.25H18C17.5858 13.25 17.25 13.5858 17.25 14C17.25 14.4142 17.5858 14.75 18 14.75H21ZM2 0.25C1.58579 0.25 1.25 0.585786 1.25 1V5C1.25 5.41421 1.58579 5.75 2 5.75C2.41421 5.75 2.75 5.41421 2.75 5V1.75H5C5.41421 1.75 5.75 1.41421 5.75 1C5.75 0.585786 5.41421 0.25 5 0.25H2Z" fill="#FFFFFF"/>
</svg>
</template>

<script>
export default {
    name: 'BiometricIcon'
}
</script>