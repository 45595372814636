<template>
    <div class="wrapper">
        <div class="container">
            <header>
                <img :src="src" alt="logo" v-if="src.length">
            </header>
            <main>
                <form action="">
                    <input-vue label="Логин" v-model:input="login" :error="loginError"/>
                    <input-vue label="Пароль" v-model:input="pass" :error="passError" type="password"/>
                    <div class="fogot-link" @click="showFogot = true">Забыли пароль?</div>
                    <button-vue title="Войти" @click="signin" class="auth-btn"/>
                    <button-vue v-if="showBiometric" title="Войти с биометрией" @click="wasignin" class="auth-btn wauth-btn">
                        <biometric-vue />
                    </button-vue>
                </form>
            </main>
            <footer>© Off Group {{year}}</footer>
        </div>
    </div>
    <page-popup-vue :show="showFogot" @close="showFogot = false">
        <block class="popup-block">
            <p class="title">Восстановление пароля</p>
            <p>Вам необходимо обратиться к представителю компании, которая предоставила вам данный личный кабинет. </p>
            <p>Вам будет сгенерирован временный пароль для входа, после чего вы сможете  установить  свой собственный.</p>
        </block>
    </page-popup-vue>
</template>

<script>
import InputVue from '../ui/Input.vue';
import ButtonVue from '../ui/Button.vue';
import users from '@/api/users';
import { mapActions } from 'vuex';
import webAuth from '@/libs/webauth'
import BiometricVue from '../svg/Biometric.vue';
import PagePopupVue from '../ui/PagePopup.vue';
import Block from '../blocks/Block.vue';

export default {
    components:{
        InputVue,
        ButtonVue,
        BiometricVue,
        PagePopupVue,
        Block
    },
    data(){
        return {
            login: '',
            pass: '',
            year: (new Date).getFullYear(),
            loginError: '',
            passError: '',
            src: '',
            webAuthProccessing: false,
            showBiometric: false,
            showFogot: false
        }
    },
    methods:{
        ...mapActions('general', ['getLogo']),
        async signin(){
            if(!this.check()) return;
            const res = await users.auth(this.login, this.pass);
            if(res) this.$router.go(0);
        },
        check(){
            let isCheck = true;
            if(!this.login.length){
                this.loginError = 'Заполните поле';
                isCheck = false;
            }
            if(!this.pass.length){
                this.passError = 'Заполните поле';
                isCheck = false;
            }

            return isCheck;
        },
        async setLogo(){
            this.src = await this.getLogo();
        },
        async wasignin(){
            if(this.webAuthProccessing) return;
            try{
                this.webAuthProccessing = true;
                const res = await webAuth.validate();
                if(res) this.$router.go(0);
            } catch (e) {
                this.webAuthProccessing = false;
                console.error(e);
            }
        },
        async init(){
            this.showBiometric = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
        }
    },
    created(){
        this.init();
        this.setLogo();
    }
}
</script>
<style>
html, body,body #app {
    height: 100%;
    display: block;
}
</style>
<style scoped>
.wrapper, .container {
    height: 100%;
}
.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
header{
    width: 100%;
}
header img{
    width: 100%;
    max-width: 250px;
    margin: 70px auto 0;
    display: block;
}
main{
    margin-bottom: 30px;
}
footer{
    margin-bottom: 25px;
}
.auth-btn{
    margin-top: 30px;
    margin-left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    max-width: 260px;
    text-align: center;
    padding: 15px 13px;
}
.wauth-btn{
    background-color: rgb(144 47 173);
    display: flex;
    justify-content: center;
    line-height: 22px;
}
.wauth-btn svg{
    margin-right: 5px;
}
.fogot-link{
    font-size: 14px;
    font-weight: 700;
    text-align: right;
    color: #838A94;
}
.popup-block .title{
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #81868D;
}
.popup-block p{
    font-size: 12px;
    font-weight: 400;
    color: #555F67;
}
</style>