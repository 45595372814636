import { ref } from "vue";
export const isAuth = ref(false);
const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
    let [resource, config ] = args;
    // перехватчик запросов здесь
    const response = await originalFetch(resource, config);
    // перехватчик ответов здесь
    if(+response.status === 401){
        isAuth.value = false;
    }
    return response;
};

